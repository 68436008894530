import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAuth } from '../utils/authContext';
import Layout from '../components/Layout';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { GET_CATALOG_STATISTICS } from '../graphql/queries';
import LoadingSpinner from '../components/LoadingSpinner';

const Statistics = () => {
    const { user, catalogs } = useAuth();
    const [duration, setDuration] = useState(7); // Default to 7 days

    const catalogId = user?.selectedCatalog?.id || catalogs[0]?.id;

    const { data, loading, error, refetch } = useQuery(GET_CATALOG_STATISTICS, {
        variables: { catalogId, days: duration },
        skip: !catalogId,
    });

    //refetch when component mount
    useEffect(() => {
        const fetchFreshData = async () => {
            if (refetch) {
                await Promise.all([refetch()]);
            }
        };

        fetchFreshData();
    }, [refetch]);


    if (!catalogId) {
        return <Layout title="Statistics"><div className='text-center'>Network error. Please refresh the page</div></Layout>;
    }

    if (loading) {
        return <Layout title="Statistics"><LoadingSpinner /></Layout>;
    }

    if (error) {
        return <Layout title="Statistics"><div>Error: {error.message}</div></Layout>;
    }

    const {
        totalCategories,
        totalItems,
        totalItemClicks,
        performancePercentageChange,
        dailyVisits,
        topItems
    } = data.getCatalogStatistics;

    return (
        <Layout title="Statistics" description="Have an insight on your catalog">
            <div className="container mx-auto p-4 space-y-6 mb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-2">Total Categories</h2>
                        <p className="text-4xl font-bold text-[#191970]">{totalCategories}</p>
                    </div>
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-2">Total Items</h2>
                        <p className="text-4xl font-bold text-[#191970]">{totalItems}</p>
                    </div>
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-2">Total Item Clicks</h2>
                        <p className="text-4xl font-bold text-[#191970]">{totalItemClicks}</p>
                    </div>
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-2">Performance Change</h2>
                        <p className={`text-4xl font-bold ${performancePercentageChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {performancePercentageChange.toFixed(2)}%
                        </p>
                    </div>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">Daily Visits</h2>
                    <div className="h-[300px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={dailyVisits}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="visits" stroke="#191970" strokeWidth={2} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">Top Clicked Items</h2>
                    <ul className="space-y-2">
                        {topItems.map((item, index) => (
                            <li key={index} className="flex justify-between items-center">
                                <span>{item.name}</span>
                                <span className="font-semibold">{item.clickCount} clicks</span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="flex justify-end">
                    <select
                        value={duration}
                        onChange={(e) => setDuration(Number(e.target.value))}
                        className="bg-white mb-10 border border-gray-300 py-3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    >
                        <option value={7}>Last 7 Days</option>
                        <option value={14}>Last 14 Days</option>
                        <option value={30}>Last 30 Days</option>
                    </select>
                </div>
            </div>
        </Layout>
    );
};

export default Statistics;