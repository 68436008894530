import React from 'react';
import OnboardingFlow from './OnboardingFlow';

const OnboardingPopup = ({ isOpen, onComplete, onSkip, initialStep }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl overflow-hidden">
        <div className="p-6">
          <OnboardingFlow
            onComplete={onComplete}
            onSkip={onSkip}
            initialStep={initialStep}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingPopup;