// BetaBanner
import React, { useState } from 'react';
import { X } from 'lucide-react';
import FeedbackSystem from './FeedbackPopup';

const DismissibleBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-[#191970] text-white py-2 px-4 flex items-center justify-between relative">
      <p className="text-sm font-medium mr-4">
        Welcome to Pricilog Beta! We're excited to have you try our new platform.
      </p>
      <div className="flex items-center">
        <FeedbackSystem />
{/*         <button
          onClick={() => setIsVisible(false)}
          className="ml-4 text-white hover:text-gray-200 bg-transparent focus:outline-none"
          aria-label="Close banner"
        >
          <X size={20} />
        </button> */}
      </div>
    </div>
  );
};

const BetaBanner = () => {

    return (
        <>
         <DismissibleBanner />
        </>
    );
};

export default React.memo(BetaBanner);
