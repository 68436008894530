import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import Layout from '../components/Layout';
import { User, Shield, CreditCard, Edit, Key, RefreshCcw, Smartphone, ToggleLeft, ToggleRight, Check, X, Plus, MoreVertical, AlertTriangle, Trash2 } from 'lucide-react';
import ChangePasswordPopup from '../components/ChangePasswordPopup';
import TwoFactorAuthPopup from '../components/TwoFactorAuthPopup';
import { useAuth } from '../utils/authContext';
import { useNavigate } from 'react-router-dom';
import { GET_USER_CATALOGS, GET_CURRENT_USER } from '../graphql/queries';
import { UPDATE_USER_MUTATION, DELETE_CATALOG_MUTATION, DELETE_ACCOUNT_MUTATION, UPDATE_SELECTED_CATALOG } from '../graphql/mutations';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const Profile = () => {
    const { updateAuthContext, refetchUserData, logout } = useAuth();
    const navigate = useNavigate();
    const [updateSelectedCatalogMutation] = useMutation(UPDATE_SELECTED_CATALOG);

    const [activeTab, setActiveTab] = useState('security');
    const [isTwoFactorAuthOpen, setIsTwoFactorAuthOpen] = useState(false);

    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [deleteCatalogConfirmation, setDeleteCatalogConfirmation] = useState('');
    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
    const [deleteAccountConfirmation, setDeleteAccountConfirmation] = useState('');
    const [catalogToDelete, setCatalogToDelete] = useState(null);
    const [deletionResult, setDeletionResult] = useState(null);
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);
    const [isConfirmingAccountDeletion, setIsConfirmingAccountDeletion] = useState(false);
    const [accountDeletionConfirmation, setAccountDeletionConfirmation] = useState('');
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);


    const { data: userData, loading: userLoading, error: userError } = useQuery(GET_CURRENT_USER);
    const [getUserCatalogs, { data: catalogsData, loading: catalogsLoading, error: catalogsError }] = useLazyQuery(GET_USER_CATALOGS);





    const [updateUser] = useMutation(UPDATE_USER_MUTATION);
    const [updateSelectedCatalog] = useMutation(UPDATE_SELECTED_CATALOG);
    const [deleteCatalog] = useMutation(DELETE_CATALOG_MUTATION, {
        onCompleted: (data) => {
            setDeletionResult(data.deleteCatalog);
            setIsConfirmingDeletion(false);
            if (data.deleteCatalog.success) {
                refetchUserData();
            }
        },
        onError: (error) => {
            console.error('Error deleting catalog:', error);
            setDeletionResult({ success: false, message: error.message });
            setIsConfirmingDeletion(false);
        }
    });


    useEffect(() => {
        if (userData?.getCurrentUser) {
            setEditName(userData.getCurrentUser.name);
            setEditEmail(userData.getCurrentUser.email);
            getUserCatalogs({ variables: { userId: userData.getCurrentUser.id } });
        }
    }, [userData, getUserCatalogs]);

    if (userLoading || catalogsLoading) return <LoadingSpinner />;
    if (userError) return <div>Error loading user data: {userError.message}</div>;
    if (catalogsError) return <div>Error loading catalogs: {catalogsError.message}</div>;

    const user = userData?.getCurrentUser;
    const catalogs = catalogsData?.getUserWithCatalogs?.catalogs || [];

    const [is2FAEnabled, setIs2FAEnabled] = useState(user?.twoFactorEnabled || false);
    const [editName, setEditName] = useState(user?.name || '');
    const [editEmail, setEditEmail] = useState(user?.email || '');


    const handleCatalogSelect = useCallback(async (catalogId) => {
        if (!user || user.selectedCatalog?.id === catalogId) return;

        // Optimistic UI update
        updateAuthContext({ selectedCatalog: { id: catalogId } });
        localStorage.setItem('selectedCatalogId', catalogId);

        try {
            const { data } = await updateSelectedCatalogMutation({
                variables: { userId: user.id, catalogId }
            });

            if (data.updateSelectedCatalog) {
                toast.success('Selected catalog updated successfully');
                refetchUserData();
                navigate('/');
            } else {
                // Revert optimistic update
                updateAuthContext({ selectedCatalog: user.selectedCatalog });
                localStorage.setItem('selectedCatalogId', user.selectedCatalog?.id || '');
                toast.error('Failed to update selected catalog');
            }
        } catch (error) {
            // Revert optimistic update
            updateAuthContext({ selectedCatalog: user.selectedCatalog });
            localStorage.setItem('selectedCatalogId', user.selectedCatalog?.id || '');
            console.error('Error updating selected catalog:', error);
            toast.error('An error occurred while updating the selected catalog');
        }
    }, [user, updateSelectedCatalogMutation, updateAuthContext, navigate]);

    // useEffect(() => {
    //     console.log("User data:", user);
    //     console.log("Catalogs data:", catalogs);
    // }, [user, catalogs]);

    const [deleteAccount] = useMutation(DELETE_ACCOUNT_MUTATION, {
        onCompleted: (data) => {
            if (data.deleteAccount.success) {
                logout();
                navigate('/');
            } else {
                setDeletionResult(data.deleteAccount);
            }
            setIsConfirmingAccountDeletion(false);
        },
        onError: (error) => {
            console.error('Error deleting account:', error);
            setDeletionResult({ success: false, message: error.message });
            setIsConfirmingAccountDeletion(false);
        }
    });

    useEffect(() => {
        refetchUserData(); // Refetch user data when component mounts
    }, [refetchUserData]);

    const toggle2FA = () => {
        // In a real application, this would involve backend communication
        setIs2FAEnabled(!is2FAEnabled);
    };

    const handle2FAClick = () => {
        if (is2FAEnabled) {
            if (window.confirm('Are you sure you want to disable 2FA? This will make your account less secure.')) {
                toggle2FA();
            }
        } else {
            setIsTwoFactorAuthOpen(true);
        }
    };

    const handleSaveName = async () => {
        try {
            await updateUser({ variables: { id: user.id, name: editName } });
            setIsEditingName(false);
        } catch (error) {
            console.error('Error updating name:', error);
        }
    };

    const handleSaveEmail = async () => {
        try {
            await updateUser({ variables: { id: user.id, email: editEmail } });
            setIsEditingEmail(false);
        } catch (error) {
            console.error('Error updating email:', error);
        }
    };

    const handleDeleteCatalog = async () => {
        if (!catalogToDelete) return;

        setIsConfirmingDeletion(true);
        try {
            await deleteCatalog({
                variables: { id: catalogToDelete.id }
            });
            await handleCatalogSelect(catalogs[0].id)
            refetchUserData();
        } catch (error) {
            console.error('Error in handleDeleteCatalog:', error);
            setIsConfirmingDeletion(false);
        }
    };

    const closeDeletionModal = () => {
        setCatalogToDelete(null);
        setDeletionResult(null);
        setIsConfirmingDeletion(false);
    };


    const handleDeleteAccount = async () => {
        if (accountDeletionConfirmation.toLowerCase() !== 'delete my account') {
            return;
        }

        setIsConfirmingAccountDeletion(true);
        try {
            await deleteAccount();
            refetchUserData();
        } catch (error) {
            console.error('Error in handleDeleteAccount:', error);
            setIsConfirmingAccountDeletion(false);
        }
    };

    const closeAccountDeletionModal = () => {
        setIsConfirmingAccountDeletion(false);
        setAccountDeletionConfirmation('');
    };

    const redirectToNewCatalogPage = () => {
        navigate('/catalog-setup');
    };


    if (!user) {
        return <LoadingSpinner />;
    }

    return (
        <Layout title="My Profile" description="See your personal details">
            <div className="p-1 sm:p-1 lg:p-8 bg-gray-100 mb-20">
                <div className="flex flex-col lg:flex-row gap-8">
                    {/* User Info and Catalogs */}
                    <div className="w-full lg:w-1/3">
                        <div className="bg-white rounded-lg shadow-md p-6 h-full">
                            <div className="flex items-center mb-10">
                                <div className="flex-grow">
                                    {isEditingName ? (
                                        <div className="flex items-center">
                                            <input
                                                type="text"
                                                value={editName}
                                                onChange={(e) => setEditName(e.target.value)}
                                                className="border rounded px-2 py-1 mr-2 bg-white flex-grow"
                                            />
                                            <button onClick={handleSaveName} className="text-green-500 bg-gray-100 hover:text-green-700 mr-2">
                                                <Check size={20} />
                                            </button>
                                            <button onClick={() => setIsEditingName(false)} className="text-red-500 bg-gray-100 hover:text-red-700">
                                                <X size={20} />
                                            </button>
                                        </div>
                                    ) : (
                                        <h4 className="text-xl font-bold flex items-center justify-between">
                                            {user.name}
                                            <button onClick={() => setIsEditingName(true)} className="mr-1 text-gray-500 bg-white hover:text-gray-700">
                                                <Edit size={16} />
                                            </button>
                                        </h4>
                                    )}
                                    {isEditingEmail && !user.isGoogleAccount ? (
                                        <div className="flex items-center mt-1 ">
                                            <input
                                                type="email"
                                                value={editEmail}
                                                onChange={(e) => setEditEmail(e.target.value)}
                                                className="border rounded px-2 py-1 mr-2 bg-white flex-grow"
                                            />
                                            <button onClick={handleSaveEmail} className="text-green-500 bg-gray-100 hover:text-green-700 mr-2">
                                                <Check size={20} />
                                            </button>
                                            <button onClick={() => setIsEditingEmail(false)} className="text-red-500 bg-gray-100 hover:text-red-700">
                                                <X size={20} />
                                            </button>
                                        </div>
                                    ) : (
                                        <p className="text-gray-600 flex items-center justify-between">
                                            {user.email}
                                            {!user.isGoogleAccount && (
                                                <button onClick={() => setIsEditingEmail(true)} className="mr-1 text-gray-500 bg-white hover:text-gray-700">
                                                    <Edit size={16} />
                                                </button>
                                            )}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <h4 className="text-lg font-semibold mb-2">Your Catalogs</h4>
                            {catalogs && catalogs.length > 0 ? (
                                <ul className="mb-4">
                                    {catalogs.map((catalog) => (
                                        <li
                                            key={catalog.id}
                                            className={`flex items-center justify-between p-2 rounded-md ${user.selectedCatalog?.id === catalog.id ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                                        >
                                            <div
                                                className="flex items-center cursor-pointer flex-grow"
                                                onClick={() => handleCatalogSelect(catalog.id)}
                                            >
                                                <img src={catalog.logo || '/../logo-placeholder.svg'} alt={`${catalog.name} logo`} className="w-8 h-8 mr-2 rounded-full" />
                                                <span>{catalog.catalogName}</span>
                                            </div>
                                            <div className="relative">
                                                <button
                                                    onClick={() => setCatalogToDelete(catalog)}
                                                    className="text-gray-500 bg-gray-100 hover:text-gray-700"
                                                >
                                                    <Trash2 className="text-red-600" size={16} />
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="mb-4">No catalogs found.</p>
                            )}

                            <button
                                onClick={redirectToNewCatalogPage}
                                className="flex items-center justify-center w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300"
                            >
                                <Plus size={16} className="mr-2" />
                                Create New Catalog
                            </button>
                        </div>
                    </div>


                    {/* Tabs */}
                    <div className="w-full lg:w-2/3">
                        <div className="bg-gray-100 rounded-lg p-2 mb-4">
                            <button
                                className={`px-4 py-2 rounded-md mr-2 ${activeTab === 'security' ? 'bg-white shadow-md' : 'hover:bg-gray-200'
                                    }`}
                                onClick={() => setActiveTab('security')}
                            >
                                <Shield className="inline-block mr-2" size={16} />
                                Security
                            </button>
                            {/* <button
                                className={`px-4 py-2 rounded-md ${activeTab === 'billing' ? 'bg-white shadow-md' : 'hover:bg-gray-200'
                                    }`}
                                onClick={() => setActiveTab('billing')}
                            >
                                <CreditCard className="inline-block mr-2" size={16} />
                                Billing
                            </button> */}
                        </div>

                        <div className="bg-white rounded-lg shadow-md p-6">
                            {/* {activeTab === 'security' ? ( */}
                            <div>
                                <h4 className="text-xl font-semibold mb-4">Security Settings</h4>
                                <div className="space-y-6">
                                    <div>
                                        <h4 className="font-medium mb-2">Change Password</h4>
                                        <button
                                            className="bg-[#191970] text-white px-4 py-2 rounded-md hover:bg-opacity-90"
                                            onClick={() => setIsChangePasswordOpen(true)}
                                        >
                                            <Key className="inline-block mr-2" size={16} />
                                            Change Password
                                        </button>
                                    </div>
                                    {/* <div>
                                            <h4 className="font-medium mb-2">Reset Password</h4>
                                            <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">
                                                <RefreshCcw className="inline-block mr-2" size={16} />
                                                Reset Password
                                            </button>
                                        </div> */}
                                    {/* <div>
                                            <h4 className="font-medium mb-2">Two-Factor Authentication</h4>
                                            <div className="flex items-center justify-between">
                                                <button
                                                    className={`flex items-center px-4 py-2 rounded-md text-white ${is2FAEnabled ? 'bg-red-500 hover:bg-red-600' : 'bg-[#191970] hover:bg-opacity-90'
                                                        }`}
                                                    onClick={handle2FAClick}
                                                >
                                                    {is2FAEnabled ? (
                                                        <ToggleRight className="mr-2" size={16} />
                                                    ) : (
                                                        <ToggleLeft className="mr-2" size={16} />
                                                    )}
                                                    {is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
                                                </button>
                                                <span className={`text-sm ${is2FAEnabled ? 'text-green-600' : 'text-red-600'}`}>
                                                    {is2FAEnabled ? 'Enabled' : 'Disabled'}
                                                </span>

                                            </div>
                                            {is2FAEnabled && (
                                                <p className="text-sm text-gray-600 mt-2 mb-2">
                                                    Two-factor authentication is currently enabled for your account.
                                                </p>
                                            )}

                                        </div> */}
                                </div>
                            </div>
                            {/* ) : (
                                <div>
                                    <h4 className="text-xl font-semibold mb-4">Billing Information</h4>
                                    <div className="mb-6">
                                        <h4 className="font-medium mb-2">Current Plan</h4>
                                        <p className="text-gray-600">Premium Plan ($49/month)</p>
                                        <button className="mt-2 bg-[#191970] text-white px-4 py-2 rounded-md hover:bg-opacity-90">
                                            <Edit className="inline-block mr-2" size={16} />
                                            Change Plan
                                        </button>
                                    </div>
                                    <div>
                                        <h4 className="font-medium mb-2">Past Invoices</h4>
                                        <ul className="space-y-2">
                                            <li className="flex justify-between items-center">
                                                <span>Invoice #1234</span>
                                                <span className="text-gray-600">$49.00</span>
                                            </li>
                                            <li className="flex justify-between items-center">
                                                <span>Invoice #1235</span>
                                                <span className="text-gray-600">$49.00</span>
                                            </li>
                                            <li className="flex justify-between items-center">
                                                <span>Invoice #1236</span>
                                                <span className="text-gray-600">$49.00</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )} */}
                        </div>
                        <div>
                            {/* Add the Delete Account section */}
                            <div className="mt-10 bg-red-100 p-3 rounded-xl">
                                {/* <hr className="mb-8" /> */}
                                <h4 className="font-medium mb-2">Delete Account</h4>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                                    onClick={() => setIsConfirmingAccountDeletion(true)}
                                >
                                    <AlertTriangle className="inline-block mr-2" size={16} />
                                    Delete Account
                                </button>
                                <p className="text-sm text-gray-600 mt-2">
                                    Warning: This action is irreversible and will permanently delete your account and all associated data.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                <ChangePasswordPopup isOpen={isChangePasswordOpen} onClose={() => setIsChangePasswordOpen(false)} />
                <TwoFactorAuthPopup isOpen={isTwoFactorAuthOpen} onClose={() => setIsTwoFactorAuthOpen(false)} />

                {/* Catalog Delete Confirmation Modal */}
                {(catalogToDelete || deletionResult) && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-lg p-6 max-w-md">
                            {!deletionResult ? (
                                <>
                                    <h4 className="text-lg font-semibold mb-4">Delete Catalog</h4>
                                    <p className="mb-4">Are you sure you want to delete the catalog "{catalogToDelete.name}"? This action cannot be undone.</p>
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={closeDeletionModal}
                                            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleDeleteCatalog}
                                            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                                            disabled={isConfirmingDeletion}
                                        >
                                            {isConfirmingDeletion ? 'Deleting...' : 'Delete Catalog'}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h4 className="text-lg font-semibold mb-4">Deletion Result</h4>
                                    <div className={`mb-4 ${deletionResult.success ? 'text-green-600' : 'text-red-600'}`}>
                                        <p>{deletionResult.message}</p>
                                        {deletionResult.success && (
                                            <p>
                                                Deleted: {deletionResult.deletedCategories} categories,
                                                {deletionResult.deletedItems} items,
                                                {deletionResult.deletedImages} images
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            onClick={closeDeletionModal}
                                            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {/* Account Deletion Confirmation Modal */}
                {isConfirmingAccountDeletion && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-lg p-6 max-w-md">
                            <h4 className="text-lg font-semibold mb-4">Delete Account</h4>
                            <p className="mb-4">Are you absolutely sure you want to delete your account? This action cannot be undone and will result in the permanent loss of all your data, including catalogs, categories, items, and images.</p>
                            <p className="mb-4">To confirm, please type "delete my account" in the box below:</p>
                            <input
                                type="text"
                                value={accountDeletionConfirmation}
                                onChange={(e) => setAccountDeletionConfirmation(e.target.value)}
                                className="w-full p-2 border bg-white text-black rounded mb-4"
                                placeholder="Type 'delete my account' to confirm"
                            />
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={closeAccountDeletionModal}
                                    className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDeleteAccount}
                                    disabled={accountDeletionConfirmation.toLowerCase() !== 'delete my account'}
                                    className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Delete Account
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Account Deletion Result Modal */}
                {deletionResult && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-lg p-6 max-w-md">
                            <h4 className="text-lg font-semibold mb-4">Account Deletion Result</h4>
                            <div className={`mb-4 ${deletionResult.success ? 'text-green-600' : 'text-red-600'}`}>
                                <p>{deletionResult.message}</p>
                                {deletionResult.success && (
                                    <p>
                                        Deleted: {deletionResult.deletedCatalogs} catalogs,
                                        {deletionResult.deletedCategories} categories,
                                        {deletionResult.deletedItems} items,
                                        {deletionResult.deletedImages} images
                                    </p>
                                )}
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setDeletionResult(null)}
                                    className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </Layout >
    );
};

export default Profile;