import React from 'react';
import ItemCard from './ItemCard';

const ItemGrid = ({ items, onToggleItemVisibility, onOpenDetail }) => {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 bg-white p-5 rounded-b-xl shadow-lg">
            {items && items.length > 0 ? (
                items.map(item => (
                    <ItemCard
                        key={item.id}
                        item={{
                            ...item,
                            price: item.priceVariants && item.priceVariants.length > 0
                                ? item.priceVariants[0].price
                                : null
                        }}
                        onToggleVisibility={onToggleItemVisibility}
                        onOpenDetail={onOpenDetail}
                    />
                ))
            ) : (
                <div className="col-span-full text-center py-8 text-gray-500">
                    No items found. <br />Please create new items using the New Item button.
                </div>
            )}
        </div>
    );
};

export default ItemGrid;