import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from '../utils/authContext';
import Layout from '../components/Layout';
import { Save, Plus, Trash2, ChevronDown, ChevronUp, Eye, Grid, ListIcon, LayoutGrid, LayoutList } from 'lucide-react';
import { UPDATE_CATALOG } from '../graphql/mutations';
import { GET_CATALOG, GET_CATALOG_BY_NAME } from '../graphql/queries';
import LoadingSpinner from '../components/LoadingSpinner';
import { defaultTheme, Modern, Classic, Blueberry, Lavender, Forest } from '../../../customer-view/src/styles/themes';
import ThemeThumbnail from '../components/ThemeThumbnail';
import CatalogPreview from '../components/CatalogPreview';
import SocialMediaSection from '../components/SocialMediaSection';
import ThemeSelector from '../components/ThemeSelector';
import FullPagePreview from '../components/FullPagePreview';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Switch, FormControlLabel, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const THEMES = ['Classic', 'Modern', 'Lavender', 'Blueberry', 'Forest', 'Taupe'];


// Main Form Component
const BrandInfoForm = ({ catalogData, setCatalogData, logoPreview, setLogoPreview, updateCatalog, catalogId, refetch }) => {
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);

    const handleSocialMediaChange = (platform, value) => {
        setCatalogData(prev => ({
            ...prev,
            socialMedia: {
                ...prev.socialMedia,
                [platform]: value
            }
        }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCatalogData(prev => ({ ...prev, [name]: value }));
    };

    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setIsUploadingLogo(true);
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64String = reader.result.split(',')[1];
                try {
                    await updateCatalog({
                        variables: {
                            id: catalogId,
                            logo: base64String
                        }
                    });
                    await refetch();
                    // Update local state
                    setCatalogData(prev => ({ ...prev, logo: reader.result }));
                    setLogoPreview(reader.result);
                } catch (error) {
                    console.error("Error updating logo:", error);
                    // TODO: Implement user-friendly error handling
                } finally {
                    setIsUploadingLogo(false);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    function validatePhoneNumber(phoneNumber) {
        if (phoneNumber === null || phoneNumber === '') {
            return true; // Allowing null or empty string
        }
        const phoneRegex = /^(?:\+|0)[0-9]{10,14}$/;
        return phoneRegex.test(phoneNumber);
    }

    // Handle manual toggle changes
    const handleAllowCheckoutChange = (event) => {
        setCatalogData(prev => ({ ...prev, allowCheckout: event.target.checked }));
    };

    // Automatically disable allowCheckout when phone is removed
    useEffect(() => {
        if (isCheckoutDisabled && catalogData.allowCheckout) {
            setCatalogData(prev => ({ ...prev, allowCheckout: false }));
        }
    }, [catalogData.phone, catalogData.allowCheckout, setCatalogData]);

    const isCheckoutDisabled = !catalogData.phone;

    return (
        <div className="space-y-4">
            <div className="flex flex-col items-center">
                {logoPreview || catalogData.logo ? (
                    <img src={logoPreview || catalogData.logo} alt="Logo Preview" className="w-32 h-32 object-cover rounded-full mb-2 border-2 p-1 border-gray-300" />
                ) : (
                    <div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center mb-2 border-2 p-1 border-gray-300">
                        <span className="text-gray-500">No Logo</span>
                    </div>
                )}
                <label htmlFor="logo-upload" className={`cursor-pointer text-[#191970] py-1 px-2 rounded-md hover:bg-opacity-90 transition-colors duration-300 flex items-center ${isUploadingLogo ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    {isUploadingLogo ? (
                        <>
                            <span className="ml-2">Uploading...</span>
                        </>
                    ) : (
                        <>
                            <Plus className="mr-2" size={16} />
                            <span>{catalogData.logo ? 'Change Logo' : 'Upload Logo'}</span>
                        </>
                    )}
                </label>
                <input
                    id="logo-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleLogoChange}
                    disabled={isUploadingLogo}
                />
            </div>


            {['name', 'description', 'phone'].map((field) => (
                <div key={field}>
                    <label htmlFor={field} className="block text-gray-700 font-bold mb-2">
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    {field === 'description' ? (
                        <textarea
                            id={field}
                            className="w-full p-2 border bg-white rounded"
                            placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                            name={field}
                            value={catalogData[field]}
                            onChange={handleInputChange}
                        />
                    ) : (
                        <input
                            type="text"
                            id={field}
                            className="w-full p-2 border bg-white rounded"
                            placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                            name={field}
                            value={catalogData[field]}
                            onChange={handleInputChange}
                            onBlur={(e) => {
                                const phoneNumber = e.target.value;
                                setIsPhoneNumberValid(validatePhoneNumber(phoneNumber));
                            }}
                        />
                    )}
                    {field === 'phone' && !isPhoneNumberValid && (
                        <p className="text-red-500 mt-1">Please enter a valid phone number</p>
                    )}
                </div>
            ))}

            {/* allowCheckout section */}

            <FormControlLabel
                control={
                    <Switch
                        checked={catalogData.allowCheckout}
                        onChange={handleAllowCheckoutChange}
                        disabled={isCheckoutDisabled}
                        color="primary"
                    />
                }
                label="Allow Checkout"
            />
            <Tooltip title="Enabling this allows customers to place orders via WhatsApp. A phone number is required for this feature.">
                <IconButton>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            {isCheckoutDisabled && (
                <p className="text-sm text-gray-500">Add a phone number to enable checkout.</p>
            )}

            {/* Social Media Section */}
            <div>
                <h3 className="text-lg font-bold mb-2">Social Media</h3>
                <div className="space-y-2">
                    {[
                        { platform: 'instagram', icon: FaInstagram },
                        { platform: 'facebook', icon: FaFacebook }
                    ].map(({ platform, icon: Icon }) => (
                        <div key={platform} className="flex items-center">
                            <label htmlFor={platform} className="flex items-center text-gray-700 font-bold mr-1 w-10">
                                <Icon className="w-6 h-6" />
                            </label>
                            <input
                                type="text"
                                id={platform}
                                className="w-full p-2 border bg-white rounded"
                                placeholder={`Enter ${platform} profile URL`}
                                value={catalogData.socialMedia[platform] || ''}
                                onChange={(e) => handleSocialMediaChange(platform, e.target.value)}
                            />
                        </div>
                    ))}
                </div>
            </div>


            <button
                type="submit"
                className="w-full bg-[#191970] text-white rounded-md py-2 px-4 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300"
            >
                <Save className="h-5 w-5 mr-2" />
                Save Changes
            </button>


        </div>
    );
};

// Main Component
const Personalization = () => {
    const { user, catalogs, refetchUserData } = useAuth();
    const [logoPreview, setLogoPreview] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState(Classic);
    const [viewMode, setViewMode] = useState('grid');
    const [isCompressing, setIsCompressing] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [catalogData, setCatalogData] = useState({
        name: '',
        description: '',
        logo: '',
        phone: '',
        socialMedia: {},
        theme: '',
        allowCheckout: false,
    });

    const catalogId = useMemo(() => user?.selectedCatalog?.id || catalogs[0]?.id, [user, catalogs]);
    const catalogName = useMemo(() => user?.selectedCatalog?.catalogName || catalogs[0]?.catalogName, [user, catalogs]);

    const { loading, error, data, refetch } = useQuery(GET_CATALOG, {
        variables: { id: catalogId },
        skip: !catalogId,
    });

    const {
        loading: catalogLoading,
        error: catalogError,
        data: catalogPreviewData,
        refetch: refetchCatalogPreview
    } = useQuery(GET_CATALOG_BY_NAME, {
        variables: { catalogName },
        skip: !catalogName,
    });


    //refetch when component mount
    useEffect(() => {
        const fetchFreshData = async () => {
            if (refetch && refetchCatalogPreview) {
                await Promise.all([refetch(), refetchCatalogPreview()]);
            }
        };

        fetchFreshData();
    }, [refetch, refetchCatalogPreview]);

    const [updateCatalog] = useMutation(UPDATE_CATALOG);

    useEffect(() => {
        if (data?.catalog) {
            const { name, description, logo, phone, socialMedia, theme, viewMode, allowCheckout } = data.catalog;
            setCatalogData({ name, description, logo, phone, socialMedia: socialMedia || {}, theme, viewMode, allowCheckout });
            setLogoPreview(logo);
            setSelectedTheme(theme || 'Classic');
            setViewMode(viewMode || 'grid');
        }
    }, [data]);

    useEffect(() => {
        if (catalogPreviewData?.getCatalogByName?.catalog?.theme) {
            setSelectedTheme(catalogPreviewData.getCatalogByName.catalog.theme);
        }
        if (catalogPreviewData?.getCatalogByName?.catalog?.viewMode) {
            setViewMode(catalogPreviewData.getCatalogByName.catalog.viewMode);
        }
    }, [catalogPreviewData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            // Prepare socialMedia data, excluding __typename
            const preparedSocialMedia = Object.entries(catalogData.socialMedia).reduce((acc, [key, value]) => {
                if (key !== '__typename' && value) {  // Only include non-empty values
                    acc[key] = value;
                }
                return acc;
            }, {});

            const result = await updateCatalog({
                variables: {
                    id: catalogId,
                    name: catalogData.name,
                    description: catalogData.description,
                    phone: catalogData.phone,
                    socialMedia: preparedSocialMedia,
                    theme: selectedTheme,
                    viewMode,
                    allowCheckout: catalogData.allowCheckout,
                },
            });
            await refetch();
            await refetchUserData();
        } catch (error) {
            console.error('Error updating catalog:', error);
            // TODO: Implement user-friendly error handling
        }
    };


    const handleThemeSelect = async (themeName) => {
        setSelectedTheme(themeName);
        try {
            await updateCatalog({
                variables: {
                    id: catalogId,
                    theme: themeName
                }
            });
            // You might want to add a small delay here before refetching
            // to ensure the server has processed the change
            await new Promise(resolve => setTimeout(resolve, 500));
            await refetch();
        } catch (error) {
            console.error("Error updating theme:", error);
            // TODO: Implement user-friendly error handling
        }
    };

    const handleViewModeChange = async (mode) => {
        setViewMode(mode);
        try {
            await updateCatalog({
                variables: {
                    id: catalogId,
                    viewMode: mode
                }
            });
        } catch (error) {
            console.error("Error updating view mode:", error);
            // TODO: Implement user-friendly error handling
        }
    };

    if (!user) {
        return <p>Please log in to view this page.</p>;
    }

    if (!catalogId) {
        refetchUserData();
    }

    if (loading || catalogLoading) return <LoadingSpinner />;
    if (error || catalogError) return <p>Error: {error?.message || catalogError?.message}</p>;

    const { catalog, categoriesWithItems, uncategorizedItems } = catalogPreviewData?.getCatalogByName || {};

    // console.log("Current selected theme:", selectedTheme);

    return (
        <Layout
            title="Personalization"
            description="Customize your catalog's appearance"
        >
            <div className="p-2 sm:p-4 lg:p-6 bg-gray-100 mb-10">
                <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row gap-4">
                    <div className="flex-1 space-y-4 w-full lg:w-2/3">
                        <div className="bg-white rounded-lg shadow-md p-6">
                            <BrandInfoForm
                                catalogData={catalogData}
                                setCatalogData={setCatalogData}
                                logoPreview={logoPreview}
                                setLogoPreview={setLogoPreview}
                                updateCatalog={updateCatalog}
                                catalogId={catalogId}
                                refetch={refetch}
                            />
                        </div>



                        <div className="bg-white rounded-lg shadow-md p-6">
                            <h2 className="text-2xl font-bold mb-4">Select a Theme</h2>
                            <ThemeSelector
                                selectedTheme={selectedTheme}
                                onSelectTheme={handleThemeSelect}
                            />
                        </div>

                        <div className="bg-white rounded-lg shadow-md p-6">
                            <h2 className="text-2xl font-bold mb-4">Layout</h2>
                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    className={`px-4 py-2 rounded-md ${viewMode === 'grid' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
                                    onClick={() => handleViewModeChange('grid')}
                                >
                                    <LayoutGrid /> Grid
                                </button>
                                <button
                                    type="button"
                                    className={`px-4 py-2 rounded-md ${viewMode === 'list' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
                                    onClick={() => handleViewModeChange('list')}
                                >
                                    <LayoutList />List
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 w-full lg:w-1/3">
                        <div className="bg-white shadow-lg rounded-lg p-4 lg:sticky lg:top-4 mb-10">

                            {/* <h2 className="text-2xl font-bold mb-4">Preview</h2> */}
                            <button
                                type="button"
                                onClick={() => setIsPreviewOpen(true)}
                                className="w-full lg:hidden bg-[#191970] text-white rounded-md py-2 px-4 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300"
                            >
                                <Eye className="h-5 w-5 mr-2" />
                                Preview Catalog
                            </button>
                            <div className='hidden lg:flex justify-center'>
                                {catalog && (
                                    <CatalogPreview
                                        businessInfo={{
                                            name: catalog.name,
                                            description: catalog.description,
                                            logo: catalog.logo,
                                            phone: catalog.phone,
                                            socialMedia: catalog.socialMedia,
                                            allowCheckout: catalog.allowCheckout,
                                        }}
                                        categories={categoriesWithItems}
                                        uncategorizedItems={uncategorizedItems}
                                        themeName={selectedTheme}
                                        viewMode={viewMode}
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                </form>
            </div>

            <FullPagePreview
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                catalog={data?.catalog}
                categoriesWithItems={catalogPreviewData?.getCatalogByName?.categoriesWithItems}
                uncategorizedItems={catalogPreviewData?.getCatalogByName?.uncategorizedItems}
                selectedTheme={selectedTheme}
                viewMode={viewMode}
                onThemeChange={handleThemeSelect}
            />
        </Layout>
    );
};

export default Personalization;