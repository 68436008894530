// In a new file, e.g., tokenManager.js
import { REFRESH_TOKEN } from '../graphql/mutations';
import client, { resetClient } from '../apollo/client';

export const TokenManager = {
    getAccessToken: () => localStorage.getItem('accessToken'),
    getRefreshToken: () => localStorage.getItem('refreshToken'),
    setTokens: (accessToken, refreshToken) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    },
    clearTokens: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    },
    refreshTokens: async () => {
        const refreshToken = TokenManager.getRefreshToken();
        if (!refreshToken) return false;

        try {
            const { data } = await client.mutate({
                mutation: REFRESH_TOKEN,
                variables: { refreshToken }
            });

            const { accessToken, refreshToken: newRefreshToken } = data.refreshToken;
            TokenManager.setTokens(accessToken, newRefreshToken);
            resetClient(); // Reset Apollo Client after refreshing tokens
            return true;
        } catch (error) {
            console.error('Error refreshing token:', error);
            TokenManager.clearTokens();
            return false;
        }
    }
};