import React from 'react';
import { CheckCircle, X } from 'lucide-react';

const SuccessMessagePopup = ({ isOpen, onClose, title, message }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                <div className="flex items-center mb-4 justify-center">
                    <CheckCircle className="text-[#191970] mr-2" size={24} />
                    <h4 className="text-xl font-bold text-[#191970]">{title}</h4>
                </div>


                <p className="text-gray-600 text-center mb-4">{message}</p>
                <div className="flex justify-center">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-[#191990] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970]"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SuccessMessagePopup;