import React from 'react';
import { Image as ImageIcon } from 'lucide-react';
import Toggle from './Toggle';

const ItemCard = ({ item, onToggleVisibility, onOpenDetail }) => {
    const price = item.priceVariants && item.priceVariants.length > 0
        ? item.priceVariants[0].price
        : null;

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggleVisibility(item.id);
    };


    return (
        <div
            className="bg-white p-1 md:p-3 rounded-2xl border border-gray-300 overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300 flex flex-col h-full cursor-pointer"
            onClick={() => onOpenDetail(item)}
        >
            <div className="relative pb-[75%] w-full aspect-square bg-white">
                {item.image ? (
                    <img
                        src={item.image}
                        alt={item.name}
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-xl"
                    />
                ) : (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <ImageIcon size={48} className="text-gray-400" />
                    </div>
                )}
                <div className="absolute top-2 right-2">
                    {/* <Toggle
                        isOn={item.isVisible}
                        onToggle={handleToggle}
                    /> */}
                </div>
            </div>
            <div className="p-2 flex flex-col justify-between flex-grow">
                <div>
                    <h3 className="text-lg font-semibold mb-2 text-gray-800 line-clamp-1">{item.name}</h3>
                    <p className="text-sm text-gray-600 mb-2 line-clamp-2">{item.description}</p>
                    {price !== null ? (
                        <p className="text-lg font-bold text-[#191970]">{price.toLocaleString('en-NG', { style: 'currency', currency: 'NGN', minimumFractionDigits: 0 })}</p>
                    ) : (
                        <p className="text-lg font-bold text-gray-500">Price not set</p>
                    )}
                </div>
                {/* {item.category && (
                    <div className="mt-2">
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                            {item.category}
                        </span>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default ItemCard;