import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChevronRight, ChevronUp, ChevronDown } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { REORDER_CATALOG } from '../graphql/mutations';
import ErrorBoundary from './ErrorBoundary';

const CatalogManagement = ({ categories, items, catalogId, onReorderSuccess, onError }) => {
    const [orderedList, setOrderedList] = useState([]);
    const [isDragEnabled, setIsDragEnabled] = useState(true);
    const [reorderCatalog] = useMutation(REORDER_CATALOG);

    useEffect(() => {
        const newOrderedList = [
            ...categories.map(category => ({
                type: 'category',
                ...category,
                items: items.filter(item => item.category === category.id).sort((a, b) => a.order - b.order)
            })),
            { type: 'uncategorized', id: 'uncategorized', name: 'Uncategorized', items: items.filter(item => !item.category) }
        ];
        setOrderedList(newOrderedList);
    }, [categories, items]);

    const handleReorder = useCallback(async (newOrderedList) => {
        try {
            console.log('Attempting to reorder catalog:', newOrderedList);

            let order = 0;
            const flattenedOrder = newOrderedList.flatMap(list => {
                if (list.type === 'category') {
                    order++;
                    const categoryOrder = { id: list.id, type: 'category', order: order };
                    const itemOrders = list.items.map(item => {
                        order++;
                        return { id: item.id, type: 'item', order: order };
                    });
                    return [categoryOrder, ...itemOrders];
                } else if (list.type === 'uncategorized') {
                    return list.items.map(item => {
                        order++;
                        return { id: item.id, type: 'item', order: order };
                    });
                }
                return [];
            });

            console.log('Flattened order:', flattenedOrder);

            const { data } = await reorderCatalog({
                variables: {
                    catalogId,
                    newOrder: flattenedOrder
                }
            });

            console.log('Reorder mutation response:', data);

            if (data && data.reorderCatalog && data.reorderCatalog.success) {
                console.log('Reorder successful, updating local state');
                if (data.reorderCatalog.updatedOrder) {
                    onReorderSuccess(data.reorderCatalog.updatedOrder);
                } else {
                    console.warn('Reorder successful but no updated order returned');
                    onReorderSuccess({
                        categoriesWithItems: newOrderedList.filter(list => list.type === 'category'),
                        itemsWithoutCategory: newOrderedList.find(list => list.type === 'uncategorized')?.items || []
                    });
                }
            } else {
                throw new Error(data?.reorderCatalog?.message || 'Unknown error occurred during reordering');
            }
        } catch (error) {
            console.error('Error in handleReorder:', error);
            onError(error);
        }
    }, [reorderCatalog, catalogId, onReorderSuccess, onError]);


    const onDragEnd = useCallback((result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const newOrderedList = Array.from(orderedList);

        if (source.droppableId === destination.droppableId) {
            // Reordering within the same list
            const listIndex = newOrderedList.findIndex(item => item.id === source.droppableId);
            const list = newOrderedList[listIndex];
            const [reorderedItem] = list.items.splice(source.index, 1);
            list.items.splice(destination.index, 0, reorderedItem);
        } else {
            // Moving between lists
            const sourceListIndex = newOrderedList.findIndex(item => item.id === source.droppableId);
            const destListIndex = newOrderedList.findIndex(item => item.id === destination.droppableId);
            const sourceList = newOrderedList[sourceListIndex];
            const destList = newOrderedList[destListIndex];
            const [movedItem] = sourceList.items.splice(source.index, 1);
            destList.items.splice(destination.index, 0, movedItem);
        }

        handleReorder(newOrderedList);
    }, [orderedList, handleReorder]);

    return (
        <ErrorBoundary fallback={<div>Something went wrong in Catalog Management. Please try again.</div>}>
            <div className="bg-white rounded-xl shadow-md p-6">
                <h2 className="text-2xl font-bold mb-4">Catalog Management</h2>
                <button
                    onClick={() => setIsDragEnabled(!isDragEnabled)}
                    className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    {isDragEnabled ? 'Disable' : 'Enable'} Drag and Drop
                </button>
                <DragDropContext onDragEnd={onDragEnd}>
                    {orderedList.map((list) => (
                        <Droppable key={list.id} droppableId={list.id}>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <h3 className="font-semibold mt-4 mb-2">{list.name}</h3>
                                    {list.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isDragEnabled}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="bg-gray-100 p-3 mb-2 rounded-md flex items-center justify-between"
                                                >
                                                    <span>{item.name}</span>
                                                    {!isDragEnabled && (
                                                        <div>
                                                            <button onClick={() => moveItem(list.id, index, 'up')} disabled={index === 0}>
                                                                <ChevronUp size={16} />
                                                            </button>
                                                            <button onClick={() => moveItem(list.id, index, 'down')} disabled={index === list.items.length - 1}>
                                                                <ChevronDown size={16} />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
        </ErrorBoundary>
    );
};

export default React.memo(CatalogManagement);