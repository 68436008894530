import React, { useState } from 'react';
import { X } from 'lucide-react';

const AddCategoryPopup = ({ isOpen, onClose, onSubmit, catalogId }) => {
    const [categoryName, setCategoryName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(categoryName);  // Pass only the name string
        setCategoryName('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-xl font-bold text-[#191970]">Add New Category</h4>
                    <button onClick={onClose} className="text-gray-500 bg-gray-100 hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="categoryName" className="block text-sm font-medium text-gray-500">Category Name</label>
                        <input
                            type="text"
                            id="categoryName"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            required
                            className="mt-1 px-2 py-2 block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50"
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970]"
                        >
                            Add Category
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCategoryPopup;