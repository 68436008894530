import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../components/AuthLayout';
import { REQUEST_PASSWORD_RESET } from '../graphql/mutations';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const [requestPasswordReset, { loading }] = useMutation(REQUEST_PASSWORD_RESET, {
        onCompleted: (data) => {
            if (data.requestPasswordReset.success) {
                setMessage(data.requestPasswordReset.message);
                // In a real application, remove this setTimeout and token logging
                setTimeout(() => {
                    // console.log("Reset token (remove in production):", data.requestPasswordReset.token);
                    navigate('/login');
                }, 3000);
            } else {
                setMessage(data.requestPasswordReset.message);
            }
        },
        onError: (error) => {
            console.error('Reset password error:', error);
            setMessage('An error occurred. Please try again.');
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        requestPasswordReset({ variables: { email } });
    };

    return (
        <AuthLayout title="Reset Password" description="Enter your email to reset your password">
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 block w-full rounded-md py-2 px-2 bg-white text-black border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                </div>

                <div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#191970] hover:bg-[#191950] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {loading ? 'Sending...' : 'Reset Password'}
                    </button>
                </div>

                {message && (
                    <div className="text-sm mt-2 text-center" style={{ color: message.includes('error') ? 'red' : 'green' }}>
                        {message}
                    </div>
                )}
            </form>
        </AuthLayout>
    );
};

export default ResetPassword;