import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { ChevronDownIcon, PlusIcon, LogOutIcon, User2, User } from 'lucide-react';
import { useAuth } from '../utils/authContext';

const isPWA = () => {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
};

const Sidebar = ({ isMobile = false, isPwa = false }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { user, catalogs, logout } = useAuth();
    const isPwaMode = isPWA();

    const userData = useSelector((state) => state.user.userData);
    // const selectedCatalog = useSelector((state) => state.user.selectedCatalog);
    const selectedCatalog = user.selectedCatalog || catalogs[0]

    const navItems = [
        { to: "/overview", icon: "home-icon.svg", label: "Overview" },
        { to: "/catalog", icon: "catalog-icon.svg", label: "Catalog" },
        { to: "/personalization", icon: "personalization-icon.svg", label: "Personalization" },
        { to: "/statistics", icon: "statistics-icon.svg", label: "Statistics" },
        { to: "/help-support", icon: "help-icon.svg", label: "Help & Support", showOnMobile: false },
    ];

    return (
        <div className={`bg-white shadow-xl text-gray-800 ${isMobile ? 'w-screen' : 'lg:w-56 lg:fixed lg:h-screen'
            } flex flex-col`}>
            <div className="hidden lg:block p-4 "><a href='/'>
                <img
                    src="../pricilogFull.svg"
                    alt="Pricilog Logo"
                    className="w-1/3 mb-8 mt-4 mx-auto"
                />
            </a>
                {/* Catalog switching */}
                <div className="hidden lg:block p-1 mb-2 relative">

                    <div className='flex justify-end'>
                        <button
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="flex p-0.5 w-full items-center bg-white font-bold text-[#191970] hover:border-[#191970]"
                        >
                            <div className="flex items-center justify-between w-11/12 text-xs font-bold text-[#191970] hover:text-[#191970]">
                                <img
                                    src={selectedCatalog?.logo || '../logo-placeholder.svg'}
                                    alt="Catalog logo"
                                    className="w-10 h-10 rounded-full mr-3"
                                />
                                <span className="text-xs text-[#191970]">{selectedCatalog?.catalogName}</span>
                                <ChevronDownIcon className='' />
                            </div>

                        </button>
                        {isDropdownOpen && (
                            <div className="absolute top-full w-48 mt-2 bg-white rounded-lg shadow-lg z-10">
                                <NavLink
                                    to="/profile"
                                    className="flex items-center p-3 hover:bg-gray-100 text-[#191970]"
                                    onClick={() => setIsDropdownOpen(false)}
                                >
                                    <div className="w-6 h-6 rounded-full bg-[#191970] flex items-center justify-center mr-3">
                                        <User2 className="w-5 h-5 text-white" />
                                    </div>
                                    <span className='text-xs font-bold text-[#191970]'>Profile</span>
                                </NavLink>

                                <NavLink
                                    to="/login"
                                    className="flex items-center p-3 hover:bg-gray-100 text-blue-600"
                                    onClick={() => {
                                        setIsDropdownOpen(false);
                                        logout();
                                    }
                                    }
                                >
                                    <div className="w-6 h-6 rounded-full bg-red-500 flex items-center justify-center mr-3 text-white">
                                        <LogOutIcon className="w-5 h-5" />
                                    </div>
                                    <span className='text-red-500 text-xs font-bold'>Logout</span>
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <nav className="flex-1 font-black lg:p-6">
                <ul className={`flex lg:flex-col lg:space-y-5`}>
                    {navItems.filter(item => !isMobile || item.showOnMobile !== false).map((item) => (
                        <li key={item.to} className="flex-1 lg:flex-none">
                            <NavLink
                                to={item.to}
                                className={({ isActive }) =>
                                    `flex flex-col lg:flex-row items-center justify-center lg:justify-start ${isPwa && isMobile ? 'pb-4' : ''} p-2 lg:px-4 lg:py-3 rounded-md text-xs font-bold ${isActive ? 'bg-gray-200 text-[#191970]' : 'text-gray-400 hover:text-[#191970]'
                                    }`
                                }
                                style={({ isActive }) => ({
                                    '--icon-color': isActive ? '#191970' : '#595b5e'
                                })}
                            >
                                <div
                                    className="w-6 h-6 lg:w-4 lg:h-4 mb-1 lg:mb-0 lg:mr-3"
                                    style={{
                                        maskImage: `url(/icons/${item.icon})`,
                                        maskSize: 'cover',
                                        WebkitMaskImage: `url(/icons/${item.icon})`,
                                        WebkitMaskSize: 'cover',
                                        backgroundColor: 'var(--icon-color)',
                                    }}
                                />
                                <span className="lg:flex-1 text-sm">{item.label}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;