import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const QRCodeWithLogo = ({ url, displaySize = 256, logoUrl }) => {
    const qrSize = displaySize * 2; // Generate a larger QR code and scale it down

    return (
        <div id="qr-code-container" style={{ width: displaySize, height: 'auto' }}>
            <div className='bg-[#191970] rounded-t-2xl w-1/3 text-white font-black text-center mx-auto pb-2 text-lg'>Scan me</div>
            <div className='bg-white border-8 border-[#191970] p-3 rounded-2xl'>
                <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                    <QRCodeSVG
                        value={url}
                        size={qrSize}
                        bgColor={"#ffffff"}
                        fgColor={"#191970"}
                        level={"M"} // Increased error correction level
                        includeMargin={false}
                        style={{ width: '100%', height: 'auto' }}
                    />
                    {logoUrl && (
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                padding: displaySize * 0.02,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: displaySize * 0.25,
                                height: displaySize * 0.25,
                            }}
                        >
                            <img
                                src='/logo192.png'
                                alt="Logo"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QRCodeWithLogo;