import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/Help';
import { useAuth } from '../utils/authContext';
import CatalogSelector from '../CatalogSelector';
import LoadingSpinner from './LoadingSpinner';
import { useMutation } from '@apollo/client';
import { SEND_VERIFICATION_EMAIL } from '../graphql/mutations';
import BetaBanner from '../components/BetaBanner'

const isPWA = () => {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
};

const Layout = ({ title, description, children }) => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const { user, loading, selectedCatalog, updateSelectedCatalog } = useAuth();
    const [message, setMessage] = useState('');
    const [resendVerification] = useMutation(SEND_VERIFICATION_EMAIL);
    const isPwaMode = isPWA();

    useEffect(() => {
        if (!isPwaMode) {
            const handleScroll = () => {
                const currentScrollPos = window.pageYOffset;
                setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
                setPrevScrollPos(currentScrollPos);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [prevScrollPos, visible, isPwaMode]);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollPos = window.pageYOffset;
    //         setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    //         setPrevScrollPos(currentScrollPos);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, [prevScrollPos, visible]);

    const handleResendVerification = async () => {
        if (!user || !user.email) {
            setMessage('Email address not available. Please try logging out and in again.');
            return;
        }

        try {
            const { data } = await resendVerification({ variables: { email: user.email } });
            setMessage(data.sendVerificationEmail.message);
        } catch (error) {
            console.error('Resend verification error:', error);
            setMessage('An error occurred while resending the verification email. Please try again.');
        }
    };



    if (loading) {
        return <LoadingSpinner />;
    }

    if (!user) {
        return <div>Please log in</div>;
    }

    return (
        <div className={`flex flex-col lg:min-h-screen w-screen sm:max-w-screen bg-gray-100 ${isPwaMode ? 'min-h-screen-safe' : ''}`}>

            <div className="flex flex-col flex-grow lg:flex-row">
                <div className="hidden lg:block lg:w-56 flex-shrink-0">
                    <Sidebar />
                </div>
                <div className='p-1 w-11/12 mx-auto'>
                    <BetaBanner />
                    {user && !user.isVerified && (
                        <div className=" bg-yellow-100 border-t-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                            <p className="font-bold">Email Verification Required</p>

                            <p>Please verify your email address to access all features. Check your inbox for the verification email.</p>
                            <button
                                onClick={handleResendVerification}
                                className="mt-2 bg-yellow-500 hover:bg-yellow-600 text-white text-xs font-bold py-2 px-2 rounded"
                            >
                                Resend Verification Email
                            </button>

                            {message && <p className="mt-2 text-sm">{message}</p>}
                        </div>
                    )}
                    <div className="flex flex-col w-full">
                        <header className="flex items-center justify-between p-4 bg-gray-100">
                            <div className="flex-grow max-w-2xl">
                                <h1 className="text-2xl font-bold">{title}</h1>
                                <p className="text-sm text-gray-500">{description}</p>
                            </div>
                            <Link to="/help-support" className="lg:hidden flex items-center text-gray-600 hover:text-[#191970] font-bold mr-4">
                                <HelpIcon className="w-6 h-6" />
                            </Link>
                        </header>
                        <main className="flex-1 p-2 pr-4 bg-gray-100">
                            {children}
                        </main>
                    </div>
                </div>
            </div>
            <div
                className={`lg:hidden fixed bottom-0 left-0 right-0 transition-transform duration-300 ${isPwaMode || visible ? 'translate-y-0' : 'translate-y-full'
                    } ${isPwaMode ? 'pb-safe' : ''}`}
            >
                <Sidebar isMobile={true} isPwa={isPwaMode} />
            </div>
        </div>
    );
};

export default Layout;
