// In Verify.jsx

import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL, SEND_VERIFICATION_EMAIL } from '../graphql/mutations';
import AuthLayout from '../components/AuthLayout';

const Verify = () => {
    const [message, setMessage] = useState('');
    const { token } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state?.email;

    const [verifyEmail] = useMutation(VERIFY_EMAIL);
    const [resendVerification, { loading: resendLoading }] = useMutation(SEND_VERIFICATION_EMAIL);

    useEffect(() => {
        if (token) {
            handleVerification();
        }
    }, [token]);

    const handleVerification = async () => {
        try {
            const { data } = await verifyEmail({ variables: { token } });
            if (data.verifyEmail.success) {
                setMessage(data.verifyEmail.message);
                setTimeout(() => navigate('/login'), 3000);
            } else {
                setMessage(data.verifyEmail.message);
            }
        } catch (error) {
            console.error('Verification error:', error);
            setMessage('An error occurred during verification. Please try again.');
        }
    };

    const handleResendVerification = async () => {
        if (!email) {
            setMessage('Email address not available. Please try registering again.');
            return;
        }

        try {
            const { data } = await resendVerification({ variables: { email } });
            setMessage(data.sendVerificationEmail.message);
        } catch (error) {
            console.error('Resend verification error:', error);
            setMessage('An error occurred while resending the verification email. Please try again.');
        }
    };

    return (
        <AuthLayout
            title="Verify Your Email"
            description={token ? "Verifying your email..." : "We've sent a verification link to your email address. Please check your inbox and click the link to verify your account."}
        >
            {message && (
                <div className="mb-4 text-center text-sm" style={{ color: message.includes('success') ? 'green' : 'red' }}>
                    {message}
                </div>
            )}

            {!token && (
                <>
                    <div className="mb-8">
                        <Link to="/">
                            <button className="w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300">
                                Proceed to Login
                            </button>
                        </Link>
                    </div>

                    <p className="text-center text-sm text-gray-600">
                        Didn't receive the email?{' '}
                        <button
                            onClick={handleResendVerification}
                            disabled={resendLoading}
                            className="font-medium bg-gray-200 text-[#191970] hover:underline disabled:opacity-50"
                        >
                            {resendLoading ? 'Sending...' : 'Resend verification email'}
                        </button>
                    </p>
                </>
            )}

            <p className="mt-8 text-center text-xs text-gray-500">
                If you're having trouble, please contact our support team.
            </p>
        </AuthLayout>
    );
};

export default Verify;