import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_TIME = 3000; // 3 seconds

const themes = [
    {
        name: 'Classic',
        description: 'Traditional and elegant design',
        bgColor: '#FFFFFF',
        textColor: '#191919',
    },
    {
        name: 'Modern',
        description: 'Sleek and contemporary look',
        bgColor: '#1A1A1A',
        textColor: '#F5F5F5',
    },
    {
        name: 'Blueberry',
        description: 'Fresh and vibrant blue tones',
        bgColor: '#C4E2FC',
        textColor: '#2C5BA8',
    },
    {
        name: 'Lavender',
        description: 'Soft and soothing purple hues',
        bgColor: '#D3D3FF',
        textColor: '#663399',
    },
    {
        name: 'Forest',
        description: 'Natural and earthy green shades',
        bgColor: '#2E6F40',
        textColor: '#FFFFFF',
    },
    {
        name: 'Taupe',
        description: 'Warm and sophisticated neutrals',
        bgColor: '#826D5A',
        textColor: '#F7E6CA',
    },
];

const ThemeSelector = ({ selectedTheme, onSelectTheme }) => {
    const [localTheme, setLocalTheme] = useState(selectedTheme);
    const [isChanging, setIsChanging] = useState(false);

    const debouncedThemeChange = useCallback(
        debounce((newTheme) => {
            onSelectTheme(newTheme);
            setIsChanging(false);
        }, DEBOUNCE_TIME),
        [onSelectTheme]
    );

    useEffect(() => {
        setLocalTheme(selectedTheme);
    }, [selectedTheme]);

    const handleThemeChange = (newTheme) => {
        setLocalTheme(newTheme);
        setIsChanging(true);
        debouncedThemeChange(newTheme);
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {themes.map((theme) => (
                <div
                    key={theme.name}
                    className={`
                        relative overflow-hidden rounded-lg shadow-lg cursor-pointer
                        transition-all duration-300 transform hover:scale-102
                        ${localTheme === theme.name ? 'ring-2 ring-blue-500' : ''}
                        ${isChanging && localTheme === theme.name ? 'opacity-70' : ''}
                    `}
                    style={{ backgroundColor: theme.bgColor }}
                    onClick={() => handleThemeChange(theme.name)}
                >
                    <div className="p-4" style={{ color: theme.textColor }}>
                        <div className="flex justify-between items-center">
                            <h3 className="text-lg font-semibold">{theme.name}</h3>
                            {localTheme === theme.name && (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            )}
                        </div>
                        <p className="text-sm mt-1 opacity-80">{theme.description}</p>
                    </div>
                    {isChanging && localTheme === theme.name && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                            <span className="text-white font-semibold">Previewing...</span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ThemeSelector;