import React from 'react';
import { X, Trash2, Edit } from 'lucide-react';

const ItemDetailPopup = ({ item, isOpen, onClose, onEdit, onDelete, onToggleVisibility }) => {
    if (!isOpen || !item) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-end mb-4">
                    <div className="flex flex-row space-x-5">
                        <button
                            onClick={() => onEdit(item)}
                            className="flex items-center px-1 py-1 bg-transparent text-[#191970] rounded-md hover:bg-blue-100"
                        >
                            <Edit size={14} className='mr-2' />Edit
                        </button>
                        <button
                            onClick={() => onDelete(item.id)}
                            className="flex items-center px-1 py-1 bg-transparent text-red-500 rounded-md hover:bg-red-100"
                        >
                            <Trash2 size={14} className='mr-2' />Delete
                        </button>
                    </div>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 bg-gray-100">
                        <X size={24} />
                    </button>
                </div>
                <div className="space-y-4">
                    {item.image && (
                        <div className="w-full aspect-square relative  mx-auto">
                            <img
                                src={item.image}
                                alt={item.name}
                                className="absolute top-0 left-0 w-full h-full  object-cover rounded-lg"
                            />
                        </div>
                    )}
                    <h4 className="text-xl font-bold text-[#191970]">{item.name}</h4>
                    <p className="text-lg font-bold text-[#191970]">{item.priceVariants[0]?.price.toLocaleString('en-NG', { style: 'currency', currency: 'NGN', minimumFractionDigits: 0 })}</p>

                    {item.description && (
                        <div className="mb-2">
                            <h3 className="bg-blue-100 text-[#191970] px-4 py-2 mb-2 font-semibold w-fit rounded-md">Description</h3>
                            <p className="text-gray-600">{item.description}</p>
                        </div>
                    )}

                    {/* <p className="text-sm text-gray-500">Category: {item.category?.name || 'Uncategorized'}</p> */}
                    {item.scheduledStart && <p className="text-sm text-gray-500">Scheduled Start: {new Date(item.scheduledStart).toLocaleString()}</p>}
                    {item.scheduledEnd && <p className="text-sm text-gray-500">Scheduled End: {new Date(item.scheduledEnd).toLocaleString()}</p>}
                    {item.repeatAvailability && (
                        <p className="text-sm text-gray-500">
                            Repeat: {item.repeatAvailability[0]?.type}
                            {item.repeatAvailability[0]?.time && ` at ${item.repeatAvailability[0].time}`}
                            {item.repeatAvailability[0]?.day && ` on ${item.repeatAvailability[0].day}`}
                            {item.repeatAvailability[0]?.date && ` on the ${item.repeatAvailability[0].date}`}
                        </p>
                    )}
                </div>
                <div className="flex justify-between mt-6">
                    {/* <button
                        onClick={() => onToggleVisibility(item.id)}
                        className={`px-3 py-1 rounded-full text-sm ${item.isVisible ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                    >
                        {item.isVisible ? 'Visible' : 'Hidden'}
                    </button> */}

                </div>
            </div>
        </div>
    );
};

export default ItemDetailPopup;