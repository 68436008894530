import Layout from '../components/Layout';
import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, MessageCircle } from 'lucide-react';
import ContactFormPopup from '../components/ContactFormPopup';
import { GET_VENDOR_FAQS } from '../graphql/queries';
import LoadingSpinner from '../components/LoadingSpinner';
import { useQuery } from '@apollo/client';

// Collapsible FAQ Item Component
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-5 bg-white rounded-lg shadow-md overflow-hidden">
            <button
                className="w-full p-4 bg-white text-left flex justify-between items-center focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="font-semibold">{question}</span>
                {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {isOpen && (
                <div className="p-4 bg-gray-50">
                    <div className="prose max-w-none">{answer}</div>
                </div>
            )}
        </div>
    );
};

// Main Help and Support Page Component
const HelpSupport = () => {
    const { loading, error, data } = useQuery(GET_VENDOR_FAQS);
    const [isContactFormOpen, setIsContactFormOpen] = useState(false);

    if (loading) return <LoadingSpinner />;
    if (error) return error.message;
    // if (error) return <ErrorMessage message="Failed to load FAQs. Please try again later." />;


    return (
        <Layout
            title="Help & Support"
            description="Get the help you need here"
        >
            <div className="container mx-auto p-1 sm:p-1 lg:p-8 max-w-4xl mb-20">
                <div className="rounded-lg  mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-[#191970]">Frequently Asked Questions</h2>
                    {data.vendorFAQs.map((faq) => (
                        <FAQItem key={faq.id} question={faq.question} answer={faq.answer} />
                    ))}
                </div>

                <div className="bg-sky-100 rounded-lg shadow-lg p-6 text-center">
                    <h2 className="text-2xl font-semibold mb-2 text-[#191970]">Still Have Questions?</h2>
                    <p className="mb-4 text-gray-700">Can't find the answer you're looking for? Chat with our friendly team!</p>
                    <button
                        onClick={() => setIsContactFormOpen(true)}
                        className="inline-flex items-center px-6 py-3 bg-[#191970] text-white font-medium rounded-lg hover:bg-opacity-90 transition duration-300"
                    >
                        <MessageCircle size={20} className="mr-2" />
                        Get in Touch
                    </button>
                </div>
            </div>
            <ContactFormPopup
                isOpen={isContactFormOpen}
                onClose={() => setIsContactFormOpen(false)}
            />
        </Layout>
    );
};

export default HelpSupport;