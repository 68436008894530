import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { REFRESH_TOKEN } from '../graphql/mutations';
import { TokenManager } from '../utils/tokenManager';

const PUBLIC_GRAPHQL_URL = import.meta.env.VITE_PUBLIC_GRAPHQL_URL;

const httpLink = createHttpLink({
    uri: PUBLIC_GRAPHQL_URL,
});

const createClient = () => {
    const httpLink = createHttpLink({
        uri: PUBLIC_GRAPHQL_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = TokenManager.getAccessToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.extensions.code) {
                    case 'UNAUTHENTICATED':
                        return new Observable(observer => {
                            TokenManager.refreshTokens().then(success => {
                                if (success) {
                                    // Retry the failed request
                                    const oldHeaders = operation.getContext().headers;
                                    operation.setContext({
                                        headers: {
                                            ...oldHeaders,
                                            authorization: `Bearer ${TokenManager.getAccessToken()}`,
                                        },
                                    });
                                    observer.next(forward(operation));
                                } else {
                                    // Redirect to login if refresh failed
                                    window.location.href = '/login';
                                    observer.error(err);
                                }
                            });
                        });
                }
            }
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    return new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        cache: new InMemoryCache()
    });
};



let client = createClient();

export const resetClient = () => {
    client = createClient();
};

export default client;