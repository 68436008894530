import React, { useState, useRef } from 'react';
import { X, Smartphone, Send } from 'lucide-react';
import { parsePhoneNumber, isValidPhoneNumber, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

// Custom list of countries with names and calling codes
const countries = [
    { code: 'NG', name: 'Nigeria', callingCode: '+234' },
    { code: 'US', name: 'United States', callingCode: '+1' },
    { code: 'GB', name: 'United Kingdom', callingCode: '+44' },
    { code: 'IN', name: 'India', callingCode: '+91' },
    { code: 'CA', name: 'Canada', callingCode: '+1' },
    { code: 'AU', name: 'Australia', callingCode: '+61' },
    { code: 'DE', name: 'Germany', callingCode: '+49' },
    { code: 'FR', name: 'France', callingCode: '+33' },

    // Add more countries as needed
];

const TwoFactorAuthPopup = ({ isOpen, onClose }) => {
    const [country, setCountry] = useState('US');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [codeSent, setCodeSent] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const codeInputs = useRef([]);

    const handleSendCode = (e) => {
        e.preventDefault();
        if (isValidPhoneNumber(phoneNumber, country)) {
            const parsedNumber = parsePhoneNumber(phoneNumber, country);
            console.log('Sending code to', parsedNumber.formatInternational());
            setCodeSent(true);
            setPhoneError('');
        } else {
            setPhoneError('Please enter a valid phone number');
        }
    };

    const handleVerify = (e) => {
        e.preventDefault();
        console.log('Verifying code', verificationCode.join(''));
        onClose();
    };

    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
        setPhoneError('');
    };

    const handleCodeChange = (index, value) => {
        const newCode = [...verificationCode];
        newCode[index] = value;
        setVerificationCode(newCode);

        if (value !== '' && index < 5) {
            codeInputs.current[index + 1].focus();
        }
    };

    const getPlaceholder = () => {
        const example = getExampleNumber(country, examples);
        return example ? example.formatNational() : '';
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-[#191970]">Set Up Two-Factor Authentication</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>
                <div className="space-y-4">
                    <p className="text-sm text-gray-600">
                        We'll send a verification code to your mobile number to set up two-factor authentication.
                    </p>
                    {!codeSent ? (
                        <form onSubmit={handleSendCode} className="space-y-4">
                            <div>
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#2424db] focus:border-[#2424db] sm:text-sm"
                                >
                                    {countries.map((c) => (
                                        <option key={c.code} value={c.code}>
                                            {c.name} ({c.callingCode})
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                                    Mobile Number
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Smartphone className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        placeholder={getPlaceholder()}
                                        required
                                        className="focus:ring-[#2424db] focus:border-[#2424db] block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                                {phoneError && <p className="mt-2 text-sm text-red-600">{phoneError}</p>}
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#191970] hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970]"
                                >
                                    <Send className="mr-2 h-5 w-5" aria-hidden="true" />
                                    Send Verification Code
                                </button>
                            </div>
                        </form>
                    ) : (
                        <form onSubmit={handleVerify} className="space-y-4">
                            <div>
                                <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
                                    Verification Code
                                </label>
                                <div className="mt-1 flex justify-between">
                                    {verificationCode.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handleCodeChange(index, e.target.value)}
                                            ref={(el) => (codeInputs.current[index] = el)}
                                            className="w-10 h-10 text-center border-gray-300 rounded-md shadow-sm focus:ring-[#2424db] focus:border-[#2424db]"
                                        />
                                    ))}
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#191970] hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970]"
                                >
                                    Verify and Enable 2FA
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TwoFactorAuthPopup;