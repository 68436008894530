// AuthLayout.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const AuthLayout = ({ children, title, description, imageSrc }) => {

  const VITE_PRICILOG_URL = import.meta.env.VITE_PRICILOG_URL;

  return (
    <div className='w-screen'>
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
        <div className="w-full max-w-[90%] items-center flex rounded-lg overflow-hidden rounded-r-xl">
          {/* Form Section */}
          <div className="w-full lg:w-3/5 bg-gray-100 p-3 lg:p-8">
            <div className="lg:hidden mb-12">
              <img src="/pricilogFull.svg" alt="Logo" width={100} height={100} className="h-28 mx-auto" />
            </div>
            <h2 className="text-3xl font-bold text-center text-[#191970] mb-2">{title}</h2>
            <p className="text-center text-gray-600 mb-8">{description}</p>

            {children}

            <p className="mt-8 text-center text-xs text-gray-500">
              By continuing, you agree to our{' '}
              <Link to={`${VITE_PRICILOG_URL}/terms`} className="underline">Terms and Conditions</Link>{' '}
              and{' '}
              <Link to={`${VITE_PRICILOG_URL}/privacy`} className="underline">Privacy Policy</Link>.
            </p>
          </div>

          {/* Image Section */}
          <div className="hidden lg:block lg:w-2/5">
            <img src={imageSrc || "/banner1.svg"} alt="Banner" className="object-cover rounded-xl bg-gray-200" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
