// CatalogSelector.jsx
import React, { useState, useCallback } from 'react';
import { useAuth } from '../utils/authContext';
import { NavLink } from 'react-router-dom';
import { ChevronDownIcon, User2, LogOutIcon } from 'lucide-react';

const CatalogSelector = () => {
    const { selectedCatalog, logout } = useAuth();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = useCallback(() => setIsDropdownOpen(prev => !prev), []);

    return (
        <div className="lg:hidden mb-6 relative flex flex-row justify-between">
            <div className='flex justify-end w-[98%]'>
                <button
                    onClick={toggleDropdown}
                    className="flex items-center justify-between w-full text-sm bg-white font-bold text-gray-400 hover:text-[#191970]"
                >
                    <div className="flex items-center text-base font-bold text-[#191970]">
                        <img
                            src={selectedCatalog?.logo || '../logo-placeholder.svg'}
                            alt="Catalog logo"
                            className="w-10 h-10 rounded-full mr-3 object-cover"
                        />
                        <span>{selectedCatalog?.catalogName}</span>
                    </div>
                    <ChevronDownIcon className="w-5 h-5 ml-3" />
                </button>

                {isDropdownOpen && (
                    <div className="absolute top-full w-52 mt-2 bg-white rounded-lg shadow-lg z-10">
                        <NavLink
                            to="/profile"
                            className="flex items-center p-3 hover:bg-gray-100 text-[#191970]"
                            onClick={toggleDropdown}
                        >
                            <div className="w-8 h-8 rounded-full bg-[#191970] flex items-center justify-center mr-3">
                                <User2 className="w-5 h-5 text-white" />
                            </div>
                            <span className='font-bold text-[#191970'>Profile</span>
                        </NavLink>
                        <NavLink
                            to="/login"
                            className="flex items-center p-3 hover:bg-gray-100 text-blue-600"
                            onClick={() => {
                                toggleDropdown();
                                logout();
                            }}
                        >
                            <div className="w-8 h-8 rounded-full bg-red-500 flex items-center justify-center mr-3 text-white">
                                <LogOutIcon className="w-5 h-5" />
                            </div>
                            <span className='text-red-500 font-bold'>Logout</span>
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(CatalogSelector);