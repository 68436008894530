import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_CATALOG, UPDATE_SELECTED_CATALOG } from '../graphql/mutations';
import { CHECK_CATALOG_NAME_AVAILABILITY } from '../graphql/queries';
import AuthLayout from './AuthLayout';
import debounce from 'lodash/debounce';
import { useAuth } from '../utils/authContext';

const CatalogSetup = () => {
    const [catalogName, setCatalogName] = useState('');
    const [catalogNameError, setCatalogNameError] = useState('');
    const [isCatalogNameAvailable, setIsCatalogNameAvailable] = useState(null);
    const [suggestedCatalogNames, setSuggestedCatalogNames] = useState([]);

    const navigate = useNavigate();
    const { refetchUserData } = useAuth(); // Get refetchUserData from useAuth


    const [createCatalog, { loading: createLoading, error: createError }] = useMutation(CREATE_CATALOG);
    const [checkCatalogNameAvailability] = useLazyQuery(CHECK_CATALOG_NAME_AVAILABILITY);
    const [updateSelectedCatalog] = useMutation(UPDATE_SELECTED_CATALOG);

    const handleBack = () => {
        navigate('/profile');
    };

    const validateCatalogName = (name) => {
        if (name.includes(' ')) {
            return 'Catalog name cannot contain spaces';
        }
        if (name.length < 3 || name.length > 32) {
            return 'Catalog name must be between 3 and 32 characters';
        }
        if (!/^[a-zA-Z0-9_-]+$/.test(name)) {
            return 'Catalog name can only contain letters, numbers, underscores, and hyphens';
        }
        return null; // No error
    };

    const checkAvailability = useCallback(
        debounce(async (name) => {
            try {
                const { data } = await checkCatalogNameAvailability({ variables: { catalogName: name } });
                setIsCatalogNameAvailable(data.checkCatalogNameAvailability);
                if (!data.checkCatalogNameAvailability) {
                    const suggestions = [
                        `${name}123`,
                        `${name}_catalog`,
                        `the_${name}`,
                        `${name}_official`,
                    ];
                    setSuggestedCatalogNames(suggestions);
                } else {
                    setSuggestedCatalogNames([]);
                }
            } catch (error) {
                console.error('Error checking catalog name availability:', error);
                setIsCatalogNameAvailable(false);
            }
        }, 300),
        [checkCatalogNameAvailability]
    );

    const handleCatalogNameChange = (e) => {
        const newCatalogName = e.target.value.replace(/\s+/g, '');
        setCatalogName(newCatalogName);
        const error = validateCatalogName(newCatalogName);
        setCatalogNameError(error);
        if (!error) {
            checkAvailability(newCatalogName);
        } else {
            setIsCatalogNameAvailable(null);
            setSuggestedCatalogNames([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isCatalogNameAvailable || catalogNameError) {
            return;
        }

        const token = localStorage.getItem('accessToken' || 'refreshToken');
        if (!token) {
            console.error('No authentication token found');
            // Handle this error, perhaps redirect to login page
            return;
        }

        try {
            const { data } = await createCatalog({
                variables: { name: catalogName, catalogName }
            });
            // console.log('Catalog creation response:', data);
            if (data && data.createCatalog) {

                // Set the new catalog as the selected catalog
                await updateSelectedCatalog({
                    variables: { userId: data.createCatalog.user, catalogId: data.createCatalog.id }
                });

                await refetchUserData(); // Refetch user data including catalogs

                // Navigate to the overview page
                navigate('/overview');
            } else {
                console.error('Unexpected response from createCatalog:', data);
            }
        } catch (error) {
            console.error('Error creating catalog:', error);
            if (error.graphQLErrors) {
                error.graphQLErrors.forEach(({ message, locations, path }) => {
                    console.error('GraphQL error:', message, locations, path);
                });
            }
            if (error.networkError) {
                console.error('Network error:', error.networkError);
            }
        }
    };

    return (
        <AuthLayout
            title="Choose Your Catalog URL"
            description="This will be the web address of your catalog"
            imageSrc="/banner1.svg"
        >
            <div className="flex justify-end mb-5">
                <button
                    onClick={handleBack}
                    className="text-[#191970] text-sm bg-gray-50 hover:underline flex items-center"
                >
                    <svg className="w-3 h-3 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Profile
                </button>
            </div>


            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label htmlFor="catalogName" className="block text-sm font-medium text-gray-700 mb-1">Catalog Name</label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 text-sm lg:text-base">
                            pricilog.com/
                        </span>
                        <input
                            type="text"
                            id="catalogName"
                            className="w-full pl-[78px] pr-10 py-2 border bg-white border-gray-300 rounded-md focus:outline-none focus:ring-[#2424db] focus:border-[#2424db] text-sm lg:text-base lg:pl-[87px]"
                            placeholder="your-catalog-name"
                            value={catalogName}
                            onChange={handleCatalogNameChange}
                        />
                        {isCatalogNameAvailable !== null && (
                            <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                {isCatalogNameAvailable ? (
                                    <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                )}
                            </span>
                        )}
                    </div>
                    {catalogNameError && <p className="mt-2 text-sm text-red-600">{catalogNameError}</p>}
                    {!catalogNameError && (
                        <p className="mt-2 text-xs text-gray-500">
                            Your catalog will be accessible at <span className='text-[#191970]'>www.pricilog.com/{catalogName || 'your-catalog-name'} </span>
                        </p>
                    )}
                </div>

                {suggestedCatalogNames.length > 0 && (
                    <div className="mb-6">
                        <p className="text-sm font-medium text-gray-700 mb-2">Suggested catalog names:</p>
                        <ul className="space-y-1">
                            {suggestedCatalogNames.map((suggestedName, index) => (
                                <li key={index}>
                                    <button
                                        type="button"
                                        className="text-[#191970] hover:underline"
                                        onClick={() => setCatalogName(suggestedName)}
                                    >
                                        {suggestedName}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <button
                    type="submit"
                    className="w-full bg-[#191970] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!isCatalogNameAvailable || !!catalogNameError || createLoading}
                >
                    {createLoading ? 'Creating Catalog...' : 'Create Catalog'}
                </button>

                {createError && <p className="mt-4 text-sm text-red-600">{createError.message}</p>}
            </form>
        </AuthLayout>
    );
};

export default CatalogSetup;