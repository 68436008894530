import React from 'react';
import { ChevronRight, Twitter } from 'lucide-react';

const TwitterGuide = ({ onClose, catalogUrl, vendorName }) => {
    const steps = [
        { text: "Go to your Twitter profile and click 'Edit profile'", icon: "🔧" },
        { text: "Add your catalog link to the 'Website' field", icon: "🌐" },
        { text: "Click 'Save' to update your profile", icon: "✅" }
    ];

    return (
        <div className="bg-white rounded-lg p-4 max-w-md mx-auto">
            <h3 className="text-lg font-semibold mb-4">How to Add Your Catalog Link to Twitter</h3>

            {/* Twitter Profile Header Example */}
            <div className="mb-6 border border-gray-300 rounded-lg p-4 bg-gray-50">
                <div className="flex items-center mb-2">
                    <div className="w-12 h-12 bg-blue-400 rounded-full flex items-center justify-center mr-4">
                        <Twitter size={24} className="text-white" />
                    </div>
                    <div>
                        <h4 className="font-bold">{vendorName}</h4>
                        <p className="text-sm text-gray-600">@{vendorName.replace(/\s+/g, '').toLowerCase()}</p>
                    </div>
                </div>
                <p className="text-sm mb-2">Your business description goes here...</p>
                <a href={catalogUrl} className="text-blue-600 text-sm font-semibold" target="_blank" rel="noopener noreferrer">
                    {catalogUrl}
                </a>
            </div>

            <ol className="space-y-4">
                {steps.map((step, index) => (
                    <li key={index} className="flex items-center">
                        <span className="mr-2 text-2xl">{step.icon}</span>
                        <span className="flex-grow">{step.text}</span>
                        {index < steps.length - 1 && <ChevronRight className="text-gray-400" />}
                    </li>
                ))}
            </ol>
            <button
                onClick={onClose}
                className="mt-6 w-full bg-[#191970] text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition-colors"
            >
                Got it!
            </button>
        </div>
    );
};

export default TwitterGuide;