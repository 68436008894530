import React, { useState, useEffect } from 'react';
import Icon from './Icon';

const steps = [
    {
        title: 'Welcome to your Pricilog',
        description: 'Manage your catalog and showcase your products with style.',
        icon: '🏠',
        content: 'Create, manage, and share your product catalog with ease. Get started by exploring our features!'
    },
    {
        title: 'Overview',
        description: 'Your catalog at a glance',
        icon: '📊',
        content: 'View basic information such as number of items, categories, and top-performing items. Share your catalog\'s unique URL or generate a QR code.'
    },
    {
        title: 'Catalog',
        description: 'Create and manage your items',
        icon: '📚',
        content: 'Add, edit, and organize the items you want to display in your catalog. Showcase your products effectively.'
    },
    {
        title: 'Personalization',
        description: 'Make your catalog unique',
        icon: '🎨',
        content: 'Add your brand/business logo, description, and contact information. Preview your personalized catalog in real-time.'
    },
    {
        title: 'Statistics',
        description: 'Track your catalog\'s performance',
        icon: '📈',
        content: 'View analytics on your catalog, including daily visits and item clicks. Make data-driven decisions to improve your catalog.'
    },
    {
        title: 'Help and Support',
        description: 'We\'re here to assist you',
        icon: '❓',
        content: 'Access FAQs and a contact form for any questions or issues you may have. We\'re always ready to help!'
    }
];

const OnboardingFlow = ({ onComplete, onSkip, initialStatus = 'NOT_STARTED', initialStep = 0 }) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [status, setStatus] = useState(initialStatus);

    useEffect(() => {
        if (status === 'NOT_STARTED' && currentStep > 0) {
            setStatus('IN_PROGRESS');
        }
    }, [currentStep, status]);

    const handleNext = () => {
        const nextStep = Math.min(currentStep + 1, steps.length - 1);
        setCurrentStep(nextStep);
    };

    const handlePrevious = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    const handleFinish = () => {
        setStatus('COMPLETED');
        onComplete(steps.length - 1, 'COMPLETED');
    };

    const handleSkip = () => {
        setStatus('SKIPPED');
        onSkip(currentStep, 'SKIPPED');
    };

    const step = steps[currentStep];

    return (
        <div className="max-w-[800px] mx-auto p-5 shadow-md rounded-lg bg-white">
            <div className="mb-10">
                <h2 className="text-[#191970] text-2xl mb-2 font-bold">{step.title}</h2>
                <p className="text-gray-600 text-base">{step.description}</p>
            </div>
            <div className="flex flex-col items-center mb-5">
                <span className="text-5xl mb-10">{step.icon}</span>
                <p className="text-center text-base text-gray-800">{step.content}</p>
            </div>
            <div className="flex justify-between">
                <button
                    onClick={handlePrevious}
                    disabled={currentStep === 0}
                    className={`px-5 py-2.5 text-white rounded ${currentStep === 0
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-[#191970] cursor-pointer'
                        }`}
                >
                    Previous
                </button>
                <button
                    onClick={handleSkip}
                    className="px-5 py-2.5 bg-transparent text-[#191970] border border-[#191970] rounded cursor-pointer"
                >
                    Skip
                </button>
                {currentStep === steps.length - 1 ? (
                    <button
                        onClick={handleFinish}
                        className="px-5 py-2.5 bg-[#191970] text-white rounded cursor-pointer"
                    >
                        Finish
                    </button>
                ) : (
                    <button
                        onClick={handleNext}
                        className="px-5 py-2.5 bg-[#191970] text-white rounded cursor-pointer"
                    >
                        Next
                    </button>
                )}
            </div>
        </div>
    );
};

export default OnboardingFlow;