import React, { useState, useEffect } from 'react';
import { X, ChevronDown, ChevronUp, Plus } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { CREATE_ITEM } from '../graphql/mutations';
import imageCompression from 'browser-image-compression';


const AddItemPopup = ({ isOpen, onClose, onSubmit, categories, quickAddCategory, catalogId }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [image, setImage] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [scheduledStart, setScheduledStart] = useState('');
  const [scheduledEnd, setScheduledEnd] = useState('');
  const [repeatType, setRepeatType] = useState('');
  const [repeatTime, setRepeatTime] = useState('');
  const [repeatDay, setRepeatDay] = useState('');
  const [repeatDate, setRepeatDate] = useState('');
  const [isCompressing, setIsCompressing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formattedPrice, setFormattedPrice] = useState('');

  const [createItem] = useMutation(CREATE_ITEM);

  useEffect(() => {
    if (isOpen && quickAddCategory) {
      setCategory(quickAddCategory.id);
    }
  }, [isOpen, quickAddCategory]);

  const formatPrice = (value) => {
    // Remove any non-digit characters
    const numericValue = value.replace(/[^\d]/g, '');
    // Format with commas
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (e) => {
    const rawValue = e.target.value;
    const formattedValue = formatPrice(rawValue);
    setFormattedPrice(formattedValue);
    // Store the numeric value without commas
    setPrice(rawValue.replace(/,/g, ''));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    try {
      // const priceVariant = [{ variant: 'default', price: parseFloat(price), __typename: "PriceVariant" }];
      // const removeTypename = (key, value) => key === '__typename' ? undefined : value;
      // const cleanedPriceVariants = JSON.parse(JSON.stringify(priceVariant), removeTypename);

      const { data } = await createItem({
        variables: {
          name,
          description,
          priceVariants: [{ variant: 'default', price: parseFloat(price) }],
          catalog: catalogId,
          category: category === '' ? null : category,
          image,
          scheduledStart,
          scheduledEnd,
          repeatAvailability: repeatType ? [{
            type: repeatType,
            time: repeatTime,
            day: repeatDay,
            date: repeatDate
          }] : null,
          isVisible: true,
        },
      });
      onSubmit(data.createItem);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error creating item:', error);
      onSubmit(null, error);
    } finally {
      setIsAdding(false);
    }
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setPrice('');
    setCategory('');
    setNewCategory('');
    setImage(null);
    setShowAdvanced(false);
    setScheduledStart('');
    setScheduledEnd('');
    setRepeatType('');
    setRepeatTime('');
    setRepeatDay('');
    setRepeatDate('');
    setPreviewUrl('');
    setFormattedPrice('');
  };


  const handleImageChange = async (e) => {

    setIsCompressing(true);
    setErrorMessage('');

    const file = e.target.files[0];
    if (file) {
      try {
        // Check file size (e.g., max 100MB)
        if (file.size > 100 * 1024 * 1024) {
          throw new Error('File size exceeds 100MB. Please choose a smaller image.');
        }

        let processedFile = file;

        // Check if the file is HEIC
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          try {
            const jpegBlob = await heic2any({
              blob: file,
              toType: 'image/jpeg',
              quality: 0.8
            });
            processedFile = new File([jpegBlob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), {
              type: 'image/jpeg'
            });
          } catch (heicError) {
            throw new Error('Unable to convert HEIC image. Please try a different format.');
          }
        }

        // Check if the file is a valid image type
        if (!processedFile.type.startsWith('image/')) {
          throw new Error('Invalid file type. Please upload an image file.');
        }

        const options = {
          maxSizeMB: 4,
          maxWidthOrHeight: 1600,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(processedFile, options);

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          setImage(base64String);
          setPreviewUrl(reader.result);
          setIsCompressing(false);
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          throw new Error('Error reading file. Please try again.');

        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error processing image:', error);
        setErrorMessage(error.message || 'An error occurred while processing the image. Please try again.');
        setIsCompressing(false);
        setImage(null);
        setPreviewUrl('');
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-xl font-bold text-[#191970]">Add New Item</h4>
          <button onClick={() => { onClose(); resetForm(); }} className="text-gray-500 bg-gray-100 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">       <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-500">Name *</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="mt-1 px-2 py-2 block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
          />
        </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-500">Description <span className='text-[70%]'>(optional)</span></label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 px-2 py-2 block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-20"
              rows="3"
            ></textarea>
          </div>
          <div>
            <label htmlFor="price" className="block text-sm font-medium text-gray-500">Price *</label>
            <div className='flex flex-row'>
              <p className='mt-4 mr-2'>₦</p> <input
                type="text"
                id="price"
                value={formattedPrice}
                onChange={handlePriceChange}
                required
                className="mt-1 px-2 py-2 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
              />
            </div>
          </div>
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-500">Category <span className='text-[70%]'>(optional)</span></label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 px-2 py-2 block w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50"
            >
              <option value="">No Category</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>{cat.name}</option>
              ))}
            </select>
          </div>
          {category === 'new' && (
            <div>
              <label htmlFor="newCategory" className="block text-sm font-medium text-gray-500">New Category</label>
              <input
                type="text"
                id="newCategory"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                className="mt-1 px-2 py-2  block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
              />
            </div>
          )}
          <div>
            <label htmlFor="image" className="block text-sm font-medium text-gray-500">Image</label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              accept="image/*"
              disabled={isCompressing}
              className="sr-only"
            />
            {isCompressing && <p className="mt-2 p-4 text-sm text-[#191970]">Processing image...</p>}
            <label
              htmlFor="image"
              className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {previewUrl ? 'Change Image' : 'Upload Image'}
            </label>
            {errorMessage && (
              <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
            )}
          </div>
          {previewUrl && (
            <div className="mt-4">
              <img src={previewUrl} alt="Preview" className="pl-3 max-w-32 h-auto max-h-20 w-auto" />
            </div>
          )}
          {/* <div>
            <button
              type="button"
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="flex px-2 py-2 items-center text-[#191970] hover:text-opacity-80"
            >
              {showAdvanced ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              <span className="ml-2">Advanced Options</span>
            </button>
          </div> */}
          {/* {showAdvanced && (
            <>
              <div>
                <label htmlFor="scheduledStart" className="block text-sm font-medium text-gray-500">Scheduled Start</label>
                <input
                  type="datetime-local"
                  id="scheduledStart"
                  value={scheduledStart}
                  onChange={(e) => setScheduledStart(e.target.value)}
                  className="mt-1 px-2 py-2  block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
                />
              </div>
              <div>
                <label htmlFor="scheduledEnd" className="block text-sm font-medium text-gray-500">Scheduled End</label>
                <input
                  type="datetime-local"
                  id="scheduledEnd"
                  value={scheduledEnd}
                  onChange={(e) => setScheduledEnd(e.target.value)}
                  className="mt-1 px-2 py-2  block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
                />
              </div>
              <div>
                <label htmlFor="repeatType" className="block text-sm font-medium text-gray-500">Repeat Availability</label>
                <select
                  id="repeatType"
                  value={repeatType}
                  onChange={(e) => setRepeatType(e.target.value)}
                  className="mt-1 px-2 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
                >
                  <option value="">Select repeat type</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              {repeatType === 'daily' && (
                <div>
                  <label htmlFor="repeatTime" className="block text-sm font-medium text-gray-500">Time</label>
                  <input
                    type="time"
                    id="repeatTime"
                    value={repeatTime}
                    onChange={(e) => setRepeatTime(e.target.value)}
                    className="mt-1 px-2 py-2  block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
                  />
                </div>
              )}
              {repeatType === 'weekly' && (
                <div>
                  <label htmlFor="repeatDay" className="block text-sm font-medium text-gray-500">Day of Week</label>
                  <select
                    id="repeatDay"
                    value={repeatDay}
                    onChange={(e) => setRepeatDay(e.target.value)}
                    className="mt-1 px-2 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
                  >
                    <option value="">Select day</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                    <option value="sunday">Sunday</option>
                  </select>
                </div>
              )}
              {repeatType === 'monthly' && (
                <div>
                  <label htmlFor="repeatDate" className="block text-sm font-medium text-gray-500">Date of Month</label>
                  <input
                    type="number"
                    id="repeatDate"
                    value={repeatDate}
                    onChange={(e) => setRepeatDate(e.target.value)}
                    min="1"
                    max="31"
                    className="mt-1 px-2 py-2  block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
                  />
                </div>
              )}
            </>
          )} */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isAdding}
              className={`px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970] ${isAdding ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
              {isAdding ? 'Adding...' : 'Add Item'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddItemPopup;
