import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { RESET_PASSWORD } from '../graphql/mutations';
import AuthLayout from './AuthLayout';

const SetNewPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        console.log('Token from URL:', token);
    }, [token]);

    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
        onCompleted: (data) => {
            // console.log('Reset password response:', data);
            if (data.resetPassword.success) {
                setMessage(data.resetPassword.message);
                setTimeout(() => navigate('/login'), 3000);
            } else {
                setMessage(data.resetPassword.message);
            }
        },
        onError: (error) => {
            console.error('Reset password error:', error);
            setMessage('An error occurred. Please try again.');
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }
        // console.log('Attempting to reset password with token:', token);
        resetPassword({ variables: { token, newPassword: password } });
    };

    if (!token) {
        return <div className="text-center mt-8">{message}</div>;
    }

    return (
        <AuthLayout title="Set New Password" description="Enter a new password">

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                New Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                Confirm New Password
                            </label>
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {loading ? 'Setting Password...' : 'Set New Password'}
                            </button>
                        </div>
                    </form>

                    {message && (
                        <div className="mt-4 text-sm text-center" style={{ color: message.includes('error') ? 'red' : 'green' }}>
                            {message}
                        </div>
                    )}
                </div>
            </div>

        </AuthLayout>
    );
};

export default SetNewPassword;