import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className="flex justify-center items-center h-screen w-screen">
            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-[#191970] mx-auto"></div>
        </div>
    );
};

export default LoadingSpinner;