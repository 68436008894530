import React, { useState, useEffect } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      console.error('Uncaught error:', error, errorInfo);
      setHasError(true);
      // You can send error to your error reporting service here
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return (
      <div className="error-boundary">
        <h1>Something went wrong.</h1>
        <p>We're sorry for the inconvenience. Please try refreshing the page or contact support if the problem persists.</p>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;