import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useMutation } from '@apollo/client';
import SuccessMessagePopup from './SuccessMessagePopup';
import { SUBMIT_CONTACT_FORM } from '../graphql/mutations';

const ContactFormPopup = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [submitContactForm, { loading }] = useMutation(SUBMIT_CONTACT_FORM);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const { data } = await submitContactForm({
        variables: { name, email, message }
      });

      if (data.submitContactForm.success) {
        resetForm();
        setIsSuccessMessageOpen(true);
      } else {
        setErrorMessage(data.submitContactForm.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };


  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleSuccessMessageClose = () => {
    setIsSuccessMessageOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 overflow-y-auto">
        <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-xl font-bold text-[#191970]">Get in touch</h4>
            <button onClick={() => { onClose(); resetForm(); }} className="text-gray-500 bg-gray-100 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          <div className="mb-6 text-gray-600">
            <p>Do you have any question seeking clarity?</p>
            <p>Send us an email at <a href="mailto:support@pricilog.com" className="text-[#191970] hover:underline">support@pricilog.com</a> or use the form below:</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-500">Name *</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="mt-1 px-2 py-2 block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-500">Email *</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 px-2 py-2 block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-10"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-500">Message *</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                className="mt-1 px-2 py-2 block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-[#191970] focus:ring focus:ring-[#191970] focus:ring-opacity-50 h-32"
                rows="4"
              ></textarea>
            </div>
            {errorMessage && (
              <div className="text-red-500 text-sm">{errorMessage}</div>
            )}
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-[#191970] text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#191970]"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <SuccessMessagePopup
        isOpen={isSuccessMessageOpen}
        onClose={handleSuccessMessageClose}
        message="Your message has been sent successfully. We'll get back to you soon!"
      />
    </>
  );
};

export default ContactFormPopup;